(function ($) {
  var tm = window.tm || {};

  tm.filtres = {
    scroll: 0,

    $window: null,
    $html: null,
    $body: null,
    $header: null,

    $entete: null,
    entete: null,
    $enteteFiltres: null,
    enteteFiltres: null,
    $filtres: null,
    $sousFiltres: null,
    $sousFiltresLi: null,

    $filtreToggle: null,
    $filtresInner: null,
    filtresInner: null,
    $filtresCont: null,
    filtresCont: null,

    $events: null,

    init: function () {
      this.$enteteFiltres = $('.entete-filtres');

      if (this.$enteteFiltres.length === 0) {
        return;
      }

      this.$window = $(window);
      this.$html = $('html');
      this.$body = $('body');
      this.$header = $('#header');

      this.$entete = $('.entete');
      this.entete = this.$entete.get(0);

      this.enteteFiltres = this.$enteteFiltres.get(0);
      this.$filtres = this.$enteteFiltres.find('.entete-filtre');
      this.$sousFiltres = this.$filtres.find('.entete-filtre-liste');
      this.$sousFiltresLi = this.$sousFiltres.find('li');

      this.$filtreToggle = this.$enteteFiltres.find('.entete-filtre-toggle');
      this.$filtresInner = this.$enteteFiltres.find('.entete-filtres-inner');
      this.filtresInner = this.$filtresInner.get(0);
      this.$filtresCont = this.$filtresInner.find('> .cont');
      this.filtresCont = this.$filtresCont.get(0);

      this.$events = $('.agenda-liste > *');

      this.$filtres.on('click', this.show_subfilters);
      this.$filtreToggle.on('click', this.toggle_filters);

      if (this.$events.length > 0) {
        this.$filtres
          .filter('.entete-filtre-tout')
          .on('click', this.show_all_events);
        this.$sousFiltresLi.on('click', this.filter_events);
      }

      this.sticky();
      this.$window.on('scroll', this.sticky);

      this.$window.on('load', this.scroll_to_first);
    },

    // Affichage des sous-menus
    show_subfilters: function () {
      var $filtre = $(this),
        actif = $filtre.hasClass('actif'),
        $liste = $filtre.find('.entete-filtre-liste');

      // $enteteFiltres[ actif || !$liste.length ? 'removeClass' : 'addClass' ]( 'sous-open' );
      tm.filtres.$entete[actif || !$liste.length ? 'removeClass' : 'addClass'](
        'sous-open'
      );

      // Désactive les filtres
      tm.filtres.$filtres.not($filtre).removeClass('actif');

      // Ferme les autres sous-menus
      tm.filtres.$sousFiltres.not($liste).stop().slideUp();

      // Active / désactive le filtre
      $filtre.toggleClass('actif');

      // Ouvre / ferme le filtre
      $liste.stop().slideToggle();
    },

    // Affichage des filtres sur mobile
    toggle_filters: function () {
      var startscroll = tm.scroll.top;
      tm.filtres.$enteteFiltres.toggleClass('open');
      tm.filtres.$filtresCont.slideToggle();
      tm.filtres.$html[
        tm.filtres.$enteteFiltres.hasClass('open') ? 'addClass' : 'removeClass'
      ]('filtre-open');
      if (!tm.filtres.$enteteFiltres.hasClass('open')) {
        window.scrollTo(0, scroll);
      } else {
        scroll = startscroll;
      }
    },

    // Fixe les filtres en tête d’écran
    sticky: function () {
      var fixedAt = tm.adminbar.height;
      if (!tm.filtres.$html.hasClass('hide-header')) {
        fixedAt +=
          tm.filtres.$header.outerHeight() +
          tm.filtres.$filtresInner.outerHeight();
      }

      // if ( entete.getBoundingClientRect().top + $entete.outerHeight() <= fixedAt ) {
      //   $enteteFiltres.addClass( 'is-fixed' );
      // } else {
      //   $enteteFiltres.removeClass( 'is-fixed' );
      // }
    },

    // Descend la page au premier élément
    scroll_to_first: function () {
      var $first = tm.filtres.$events
        .filter('.agenda-ligne:not(.hide):not(.passed)')
        .first();

      if ($first.length === 0) {
        var $first = tm.filtres.$events
          .filter('.agenda-ligne:not(.hide)')
          .first();
      }

      if ($first.length > 0) {
        var scrollTo = $first.offset().top - tm.adminbar.height;
        if (!tm.responsive.is()) {
          scrollTo -= tm.filtres.$filtresCont.innerHeight();
        }
        if (scrollTo < tm.scroll.top) {
          scrollTo -= tm.filtres.$header.outerHeight();
          if (tm.responsive.is()) {
            scrollTo -= tm.filtres.$filtresInner.outerHeight();
          }
        }
        tm.scroll.to(scrollTo, true);
      }
    },

    // Fonctionnement des filtres
    // Tout afficher
    show_all_events: function () {
      tm.filtres.$sousFiltresLi.removeClass('actif');
      tm.filtres.$events.removeClass('hide');

      // Ferme les filtres mobiles
      if (tm.responsive.is()) {
        tm.filtres.$enteteFiltres.removeClass('open');
        tm.filtres.$filtresCont.slideUp();
      }

      tm.filtres.$html.removeClass('filtre-open');

      tm.filtres.scroll_to_first();
    },

    filter_events: function () {
      var $sousFiltreLi = $(this),
        value = $sousFiltreLi.attr('data-value'),
        $filtre = $sousFiltreLi.closest('.entete-filtre'),
        $liste = $filtre.find('.entete-filtre-liste');
      tm.filtres.filter = $filtre.attr('data-filter');
      tm.filtres.$show = tm.filtres.$events.filter(
        '[data-' + tm.filtres.filter + '="' + value + '"]'
      );

      tm.filtres.$sousFiltresLi.removeClass('actif');
      tm.filtres.$sousFiltresLi.addClass('actif');
      tm.filtres.$events.not(tm.filtres.$show).addClass('hide');
      tm.filtres.$show.each(function () {
        var $event = $(this),
          $h2 = $event.prevAll('.agenda-titre:first');
        $event.removeClass('hide');
        $h2.removeClass('hide');
      });

      // Ferme le filtre
      $filtre.removeClass('actif');
      $liste.stop().slideUp();
      // $enteteFiltres.removeClass( 'sous-open' );
      tm.filtres.$entete.removeClass('sous-open');

      // Ferme les filtres mobiles
      if (tm.responsive.is()) {
        tm.filtres.$enteteFiltres.removeClass('open');
        tm.filtres.$filtresCont.slideUp();
      }

      tm.filtres.$html.removeClass('filtre-open');

      tm.filtres.scroll_to_first();

      return false;
    },
  };
})(jQuery);
