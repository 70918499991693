import Flickity from 'flickity';
import $ from 'jquery';
import jQueryBridget from 'jquery-bridget';
Flickity.setJQuery($);
jQueryBridget('flickity', Flickity, $);

(function ($) {
  var tm = window.tm || {};

  tm.carrousels = {
    $cont: null,
    $carrousels: null,

    init: function ($cont) {
      this.$cont = $cont && $cont.length ? $cont : null;
      this.$carrousels = $('.slider', $cont).not('.flickity-enabled');

      this.$carrousels.each(function () {
        var $carrousel = $(this),
          $wrapper = $carrousel.parent();
        const infinite = $carrousel.hasClass('slider-infinite');
        let sync = false;

        if (
          $carrousel.hasClass('galerie-slider') &&
          $carrousel.find('figcaption').length > 0
        ) {
          var $cells = $carrousel.find('> *'),
            $legendesCarrousel = $('<div>').addClass('slider legende-slider');

          if (infinite) {
            $legendesCarrousel.addClass('slider-infinite');
          }

          $cells.each(function () {
            var $legende = $(this).find('figcaption'),
              legende = $legende.length > 0 ? $legende.html() : '';
            $legendesCarrousel.append(
              '<div class="galerie-cell">' + legende + '</div>'
            );
          });

          $wrapper.after($legendesCarrousel);

          $legendesCarrousel.flickity({
            cellAlign: 'left',
            wrapAround: infinite,
            prevNextButtons: false,
            pageDots: false,
            setGallerySize: true,
            resize: true,
            groupCells: 1,
            contain: true,
            fade: true,
            selectedAttraction: 0.1,
            friction: 0.5,
          });

          sync = $legendesCarrousel.get(0);
        }

        $carrousel.flickity({
          cellAlign: 'left',
          wrapAround: infinite,
          prevNextButtons: false,
          // arrowShape         : 'M47.3,75.5L21,49.3L47.3,23l4.6,4.6L33.5,46h40.1v6.6H33.5l18.4,18.4L47.3,75.5z',
          pageDots: false,
          setGallerySize: false,
          resize: true,
          groupCells: 1,
          contain: true,
          sync: sync,
          selectedAttraction: 0.12,
          friction: 0.8,
        });

        var flkty = $carrousel.data('flickity');
        let $prev = $carrousel.closest('.galerie').find('.galerie-button-prev');
        let $next = $carrousel.closest('.galerie').find('.galerie-button-next');

        $prev.on('click', function () {
          $carrousel.flickity('previous');
        });
        // next
        $next.on('click', function () {
          $carrousel.flickity('next');
        });

        if (!infinite) {
          $carrousel.on('select.flickity', function () {
            // Si on est sur la première slide sans slider infini, on désactive le bouton previous
            if (flkty.selectedIndex == 0) {
              $prev.addClass('galerie-button-disabled');
            } else {
              $prev.removeClass('galerie-button-disabled');
            }
            // Si on est sur la dernière slide sans slider infini, on désactive le bouton next
            if (flkty.selectedIndex == flkty.slides.length - 1) {
              $next.addClass('galerie-button-disabled');
            } else {
              $next.removeClass('galerie-button-disabled');
            }
          });
        }
      });
    },
  };
})(jQuery);
