import LazyLoad from 'vanilla-lazyload';

(function ($) {
  var tm = window.tm || {};

  tm.lazyload = {
    instance: null,

    init: function () {
      this.instance = new LazyLoad({
        elements_selector: 'img[data-src]',
        callback_loaded: function (img) {
          var $img = $(img),
            position = $img.css('object-position');

          if (!$img.hasClass('no-object-fit-polyfill')) {
            $img.attr('data-object-fit', 'cover');
            if (typeof position !== 'undefined') {
              $img.attr('data-object-position', position);
            }
            objectFitPolyfill(img);
          }

          if (
            $img.hasClass('parallax') &&
            !$img.parent().hasClass('object-fit-polyfill')
          ) {
            new simpleParallax(img, {
              delay: 0,
              // transition : 'cubic-bezier(0,0,0,1)',
              overflow: true,
            });
          }
        },
      });
    },

    update: function () {
      if (this.instance) {
        this.instance.update();
      }
    },
  };
})(jQuery);
