(function ($) {
  var tm = window.tm || {};

  tm.menu = {
    $window: null,
    $html: null,
    $body: null,
    $overlay: null,
    $header: null,
    headerH: 0,
    $menus: null,
    $menusLi: null,
    $menusA: null,
    $submenus: null,
    $submenusLi: null,
    $submenusA: null,
    $hamburger: null,
    scroll: 0,

    init: function ($cont) {
      $cont = $cont && $cont.length ? $cont : null;

      this.$window = $(window);
      this.$html = $('html');
      this.$body = $('body');

      // Overlay
      this.$overlay = $('.overlay');

      this.$overlay.on('click', this.click_overlay);

      // Header
      this.$header = $('#header');
      this.headerH = this.$header.outerHeight();

      this.auto_hide();
      this.$window.on('scroll', this.auto_hide);

      // Menus
      this.$menus = $('.menu', $cont);
      this.$menusLi = this.$menus.find('> li');
      this.$menusA = this.$menusLi.find('> a');

      this.$menusA.on('click', this.toggle_submenu);

      // Sous-menus
      this.$submenus = this.$menusA.next('.menu-sub');
      this.$submenusLi = this.$submenus.find('li');
      this.$submenusA = this.$submenusLi.find('a');

      this.$submenusLi.on('mouseenter', this.enter_submenuLi);
      this.$submenusLi.on('mouseleave', this.leave_submenuLi);
      this.$submenusA.on('click', this.click_submenuA);

      this.set_submenus_height();
      this.$window.on('resize', this.set_submenus_height);

      // Hamburger
      this.$hamburger = $('.menu-burger', $cont);
      this.$hamburger.on('click', this.click_hamburger);
    },

    // Définis la hauteur des sous-menus
    set_submenus_height: function () {
      // if ( !tm.responsive.is() ) {
      //   console.log('is responsive');
      //   var submenuH = lodash.max( $submenus.map( function() { return $(this).outerHeight(); } ).get() );
      //   $submenus.css( 'height', submenuH + 'px' );
      //   $submenus.removeAttr( 'style' );
      //   console.log(submenuH);
      // } else {
      //   console.log('not responsive');
      // }
    },

    // Affichage des sous-menus
    toggle_submenu: function () {
      var $a = $(this),
        $li = $a.parent('li'),
        open = !$li.hasClass('open'),
        $sous = $a.next('.menu-sub');

      if ($sous.length > 0) {
        tm.menu.$menusLi.removeClass('open');
        tm.menu.$submenus.not($sous).hide();
        $li[open ? 'addClass' : 'removeClass']('open');
        $sous.stop();
        // En responsive, pas d’animation sur l’apparition de la recherche
        $sous[
          open
            ? tm.menu.$body.hasClass('menu-open')
              ? 'show'
              : 'slideDown'
            : 'slideUp'
        ]();
        tm.menu.$body[open ? 'addClass' : 'removeClass']('menu-open');
        return false;
      } else {
        tm.menu.$menusLi.removeClass('open');
        tm.menu.$submenus.not($sous).stop().slideUp();
      }
    },

    // Survol des éléments de sous-menus
    enter_submenuLi: function () {
      var $li = $(this),
        $bloc = $li.find('.menu-bloc');
      this.$body[$bloc.length > 0 ? 'addClass' : 'removeClass'](
        'has-menu-bloc'
      );
    },
    leave_submenuLi: function () {
      this.$body.removeClass('has-menu-bloc');
    },

    // Clic sur les liens dans les sous-menus : fermeture du sous-menu
    click_submenuA: function () {
      var $a = $(this),
        $sous = $a.closest('.menu-sub'),
        $li = $sous.parent('li');
      $li.removeClass('open');
      $sous.stop().slideUp();
      this.$body.removeClass('menu-open');
    },

    // Clic sur l’overlay : fermeture du menu
    click_overlay: function () {
      var $li = $menusLi.filter('.open'),
        $sous = $li.find('.menu-sub');
      $li.removeClass('open');
      $sous.stop().slideUp();
      this.$body.removeClass('menu-open');
    },

    // Clic sur le bouton burger
    click_hamburger: function () {
      if (tm.menu.$body.hasClass('has-menu-mobile')) {
        window.scrollTo(0, scroll);
      } else {
        scroll = tm.scroll.top;
        tm.menu.$menusLi.removeClass('open');
        console.log(
          tm.menu.$submenus.not(function () {
            return $(this).closest('li').hasClass('menu-recherche');
          })
        );
        tm.menu.$submenus
          .not(function () {
            return $(this).closest('li').hasClass('menu-recherche');
          })
          .stop()
          .hide();
      }
      tm.menu.$body.toggleClass('has-menu-mobile');
    },

    auto_hide: function () {
      tm.menu.$html[
        !tm.menu.$body.hasClass('menu-open') &&
        !tm.menu.$html.hasClass('filtre-open') &&
        !tm.menu.$body.hasClass('has-menu-mobile') &&
        tm.scroll.direction !== 'up' &&
        tm.scroll.top > tm.menu.headerH
          ? 'addClass'
          : 'removeClass'
      ]('hide-header');
    },
  };
})(jQuery);
