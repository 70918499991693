(function ($) {
  var tm = window.tm || {};

  tm.accordeons = {
    $cont: null,
    $titres: null,

    init: function ($cont) {
      this.$cont = $cont && $cont.length ? $cont : null;
      this.$titres = $('.accordeon-titre', $cont);

      this.$titres.each(function () {
        var $titre = $(this),
          $accordeon = $titre.closest('.accordeon');

        $accordeon.addClass('closed');

        $titre.on('click', function () {
          var $titre = $(this),
            $accordeon = $titre.closest('.accordeon'),
            $contenu = $accordeon.find('> .accordeon-contenu');
          if ($accordeon.hasClass('closed')) {
            $accordeon.prop('open', true);
            $contenu.stop().slideDown(250);
          } else {
            $contenu.stop().slideUp(250, function () {
              $accordeon.removeAttr('open');
            });
          }
          $accordeon.toggleClass('closed');
          return false;
        });
      });
    },
  };
})(jQuery);
