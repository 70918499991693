// import _ from 'lodash';
(function ($) {
  var tm = window.tm || {};

  if (typeof couleur === "undefined") couleur = "#e5233d";

  tm.async = function (script, callback) {
    var s = document.createElement("script"),
      head = document.getElementsByTagName("head")[0];
    s.type = "text/javascript";
    s.async = true;
    s.defer = true;
    s.src = script;
    if (typeof callback == "function") {
      s.addEventListener(
        "load",
        function (e) {
          callback.call();
        },
        false
      );
    }
    head.appendChild(s);
  };

  tm.maps = {
    icon:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(
        [
          '<?xml version="1.0"?>',
          '<svg width="34px" height="49px" viewBox="0 0 34 49" version="1.1" xmlns="http://www.w3.org/2000/svg">',
          '<g transform="translate(-395.000000, -1807.000000)" fill="' +
            couleur +
            '">',
          '<path d="M399.871094,1812.10547 C403.194027,1808.78254 407.196591,1807.12109 411.878906,1807.12109 C416.561221,1807.12109 420.563785,1808.76366 423.886719,1812.04883 C427.209652,1815.334 428.871094,1819.31768 428.871094,1824 C428.871094,1826.34116 428.285813,1829.041 427.115234,1832.09961 C425.944656,1835.15822 424.528654,1837.99022 422.867188,1840.5957 C421.205721,1843.20118 419.544279,1845.65559 417.882812,1847.95898 C416.221346,1850.26238 414.824224,1852.09374 413.691406,1853.45312 L411.878906,1855.37891 C411.425779,1854.92578 410.821618,1854.2461 410.066406,1853.33984 C409.311194,1852.43359 407.970713,1850.67775 406.044922,1848.07227 C404.119131,1845.46678 402.401049,1842.93686 400.890625,1840.48242 C399.380201,1838.02798 398.020839,1835.25262 396.8125,1832.15625 C395.604161,1829.05988 395,1826.34116 395,1824 C395,1819.31768 396.623682,1815.35288 399.871094,1812.10547 Z M407.574219,1828.30469 C408.782558,1829.43751 410.21744,1830.00391 411.878906,1830.00391 C413.540373,1830.00391 414.975254,1829.41863 416.183594,1828.24805 C417.391933,1827.07747 417.996094,1825.66147 417.996094,1824 C417.996094,1822.33853 417.391933,1820.92253 416.183594,1819.75195 C414.975254,1818.58137 413.540373,1817.99609 411.878906,1817.99609 C410.21744,1817.99609 408.801438,1818.58137 407.630859,1819.75195 C406.460281,1820.92253 405.875,1822.33853 405.875,1824 C405.875,1825.66147 406.441401,1827.09635 407.574219,1828.30469 Z"></path>',
          "</g>",
          "</svg>",
        ].join("\n")
      ),

    init: function ($cont, args) {
      $cont = $cont && $cont.length ? $cont : null;
      var $maps = $(".gmap", $cont);
      if (
        !$maps.length ||
        (typeof google !== "undefined" && typeof google.maps !== "undefined")
      )
        return;
      var gmapcallback = function () {
        tm.maps.setup($maps);
      };
      window.gmapcallback = gmapcallback;
      args = {
        key: "AIzaSyB4KtTka4WxeypmUBFXJh4X4iGQWMF-xRA",
        callback: "gmapcallback",
        ...args,
      };
      tm.async(
        "https://maps.googleapis.com/maps/api/js" +
          (args ? "?" + $.param(args) : "")
      );
    },

    render: function (gogomap) {
      var $gogomap = $(gogomap);
      let lat = $gogomap.attr("data-lat");
      let lng = $gogomap.attr("data-lng");
      let center = new google.maps.LatLng(lat || 49.415371, lng || 2.101307);
      let zoom = 14;

      // Réglages de base
      let pageMap = new google.maps.Map(gogomap, {
        zoom: zoom,
        // maxZoom             : 17,
        center: center,
        // scrollwheel         : false,
        gestureHandling: "cooperative",
        // draggable           : true,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [
          {
            featureType: "administrative",
            elementType: "all",
            stylers: [{ saturation: "-100" }],
          },
          {
            featureType: "administrative.province",
            elementType: "all",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [
              { saturation: -100 },
              { lightness: 65 },
              { visibility: "on" },
            ],
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [
              { saturation: -100 },
              { lightness: "50" },
              { visibility: "simplified" },
            ],
          },
          {
            featureType: "road",
            elementType: "all",
            stylers: [{ saturation: "-100" }],
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [{ visibility: "simplified" }],
          },
          {
            featureType: "road.arterial",
            elementType: "all",
            stylers: [{ lightness: "30" }],
          },
          {
            featureType: "road.local",
            elementType: "all",
            stylers: [{ lightness: "40" }],
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [{ saturation: -100 }, { visibility: "simplified" }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              { hue: "#ffff00" },
              { lightness: -25 },
              { saturation: -97 },
            ],
          },
          {
            featureType: "water",
            elementType: "labels",
            stylers: [{ lightness: -25 }, { saturation: -100 }],
          },
        ],
        streetViewControl: false,
        overviewMapControl: false,
        mapTypeControl: false,
        panControl: false,
        // scaleControl        : false,
        zoomControlOptions: { style: google.maps.ZoomControlStyle.SMALL },
      });

      //maps_infowindow = new google.maps.InfoWindow({maxWidth: 205});
      //google.maps.event.addListener(pageMap, 'click', function() {
      //  maps_infowindow.close();
      //});
      //google.maps.event.addDomListener(window, 'resize', function() {
      //	maps_update_bounds();
      //});

      //maps_get_items();
      //maps_set_markers();
      if (lat && lng) {
        var marker = new google.maps.Marker({
          position: new google.maps.LatLng(lat, lng),
          icon: { url: tm.maps.icon, scaledSize: new google.maps.Size(40, 40) },
        });
        marker.setMap(pageMap);
      }
    },

    setup: function ($maps) {
      if (!$maps.length) return;
      $maps.each(function (idx, gogomap) {
        tm.maps.render(gogomap);
      });
    },
  };
})(jQuery);
