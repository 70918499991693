(function($){

  var tm = {};

  window.tm = tm;

  tm.responsive = {
    is     : function() {
      return $( 'body' ).hasClass( 'isResponsive' );
    },
    update : function() {
      $( 'body' )[ window.matchMedia( "(max-width: 860px)" ).matches ? 'addClass' : 'removeClass' ]( 'isResponsive' );
    }
  };

  tm.scroll = {
    top       : 0,
    direction : 'down',
    update    : function() {
      tm.scroll.direction = $( window ).scrollTop() < tm.scroll.top ? 'up' : 'down';
      tm.scroll.top       = $( window ).scrollTop();
    },
    to        : function( pos, animate ) {
      if ( animate ) {
        $( 'html, body' ).animate({
          scrollTop : pos,
        }, 300);
      } else {
        window.scrollTo( 0, pos );
      }
    }
  };

  tm.adminbar = {
    height : parseInt( $( 'html' ).css( 'margin-top' ) ),
  }

  tm.responsive.update();
  $( window ).on( 'resize', tm.responsive.update );

  tm.scroll.update();
  $( window ).on( 'scroll', tm.scroll.update );

})(jQuery);