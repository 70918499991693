import "./libs/objectfitpolyfill.min.js";
import "./libs/intersection-observer.min.js";
import "./libs/simpleparallax.min.js";

import "./_functions.js";
import "./_lazyload.js";
import "./_menu.js";
import "./_video.js";
import "./_entete.js";
import "./_filtres.js";
import "./_carrousels.js";
import "./_accordeons.js";
import "./_maps.js";
import "./_cookies.js";

if (typeof _ !== "undefined") {
  window.lodash = _.noConflict();
}

(function ($) {
  $(document).ready(function () {
    tm.lazyload.init();
    tm.menu.init();
    tm.entete.init();
    tm.filtres.init();
    tm.carrousels.init();
    tm.accordeons.init();
    tm.maps.init();
    tm.video.init();
  });
})(jQuery);
