(function ($) {
  var tm = window.tm || {};

  tm.entete = {
    $cont: null,
    $entete: null,
    $main: null,
    $header: null,

    init: function ($cont) {
      this.$cont = this.$cont && this.$cont.length ? this.$cont : null;
      this.$entete = $(".entete", this.$cont);

      if (this.$entete.length === 0) {
        return;
      }

      this.$main = $("#main");
      this.$header = $("#header");

      this.set_height();
      $(window).on("resize", this.set_height);
    },

    set_height: function (e) {
      if (typeof tm.entete.$entete === "undefined") {
        return;
      }

      var h = tm.entete.$header.outerHeight();
      tm.entete.$main.css("margin-top", h + "px");

      // Image carrée sur l'entête home :
      if (tm.entete.$entete.hasClass("entete_home")) {
        var $img = tm.entete.$entete.find(".entete-image img");
        $img.css("width", $img.outerHeight());
      }

      h += tm.adminbar.height;

      // $entete.css( 'min-height', 'calc(70vh - '+h+'px)' );
    },
  };
})(jQuery);
