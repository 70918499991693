$(function ($) {
  if (tarteaucitron) {
    tarteaucitron.init({
      privacyUrl: "" /* Privacy policy url */,
      hashtag: "#tarteaucitron" /* Open the panel with this hashtag */,
      cookieName: "tarteaucitron" /* Cookie name */,
      orientation: "bottom" /* Banner position (top - bottom) */,
      showAlertSmall: false /* Show the small banner on bottom right */,
      cookieslist: false /* Show the cookie list */,
      showIcon: true /* Show cookie icon to manage cookies */,
      iconPosition:
        "BottomRight" /* BottomRight, BottomLeft, TopRight and TopLeft */,
      adblocker: false /* Show a Warning if an adblocker is detected */,
      closePopup: false /* Show a close X on the banner */,
      DenyAllCta: true /* Show the deny all button */,
      AcceptAllCta: true /* Show the accept all button when highPrivacy on */,
      highPrivacy: true /* HIGHLY RECOMMANDED Disable auto consent */,
      handleBrowserDNTRequest: false /* If Do Not Track == 1, disallow all */,
      removeCredit: true /* Remove credit link */,
      moreInfoLink: true /* Show more info link */,
      useExternalCss: true /* If false, the tarteaucitron.css file will be loaded */,
      useExternalJs: false /* If false, the tarteaucitron.js file will be loaded */,
      readmoreLink: "" /* Change the default readmore link */,
      mandatory: true /* Show a message about mandatory cookies */,
    });
    (tarteaucitron.job = tarteaucitron.job || []).push("youtube");
  }
});
