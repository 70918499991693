import Plyr from "plyr";

(function ($) {
  var tm = window.tm || {};

  tm.video = {
    $entete: null,
    init: function ($cont) {
      this.$entete = $(".entete");
      this.$entete.find(".js-plyr-autoplay").each(function (idx, el) {
        var $el = $(el);
        const player = new Plyr($el, {
          muted: true,
          controls: false,
          clickToPlay: false,
          autoplay: true,
          autopause: false,
          volume: 0,
          hideControls: true,
          vimeo: {
            background: true,
            muted: true,
            dnt: true,
            autoplay: true,
          },
          fullscreen: {
            enabled: false,
            fallback: false,
            iosNative: false,
            container: null,
          },
          loop: {
            active: true,
          },
        });
        player.on("ready", function () {
          player.muted = true;
          player.play();
          var $muteToggle = $(".video_mute-toggle");
          if (!player.muted) {
            $muteToggle.addClass("not-muted");
          }
          $muteToggle.on("click", function () {
            if (player.muted) {
              player.muted = false;
              player.volume = 1;
              $muteToggle.addClass("not-muted");
            } else {
              player.muted = true;
              player.volume = 0;
              $muteToggle.removeClass("not-muted");
            }
          });
        });
        $el.data({ player });
        $(window).on("resize", function () {
          if ($(window).innerWidth() < 768) {
            player.pause();
          } else {
            player.play();
          }
        });
        $(window).on("scroll", function () {
          if ($(window).innerWidth() < 768) {
            player.pause();
          } else {
            player.play();
          }
        });
      });
    },
  };
})(jQuery);
